/* ----------------- desktop ----------------- */

.container {
  min-width: $viewport--sm;
  max-width: $viewport--xl;
  width: 100%;
  margin: 0 auto;
  padding: 0 60px;
}

/* -----------------  768px - 1024px (tablet) ----------------- */

@media (max-width: ($viewport--lg - 1)) {
  .container {
    padding: 0 35px;
  }
}

/* -----------------  320px - 768px (mobile) ----------------- */

@media (max-width: ($viewport--md - 1)) {
  .container {
    padding: 0 15px;
  }
}
