.button {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 50px;

  font-size: 21px;
  font-weight: 700;
  color: #ffffff;

  border: none;
  border-radius: 5px;
  outline: none;
  
  background-color: $color-button-add;
  box-shadow: -3px 3px 6px rgba(0, 0, 0, 0.16);
  cursor: pointer;
  z-index: 2;
  transition: $default-transition-settings;

  &::after {
    content: '';

    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;

    border-radius: 5px;
    background: $gradient-button;

    z-index: -1;
    transition: $default-transition-settings;
  }

  &:hover::after,
  &:focus::after {
    opacity: 0;
  }

  &:active {
    box-shadow: -5px 5px 9px rgba(0, 0, 0, 0.16);
  }

  &:disabled {
    opacity: 0.5;
    background: rgba(0, 0, 0, 0.16);

    cursor: auto;

    &::after {
      background: rgba(0, 0, 0, 0.16);
    }
    
    &:hover::after,
    &:focus::after {
      opacity: 1;
    }
  }

  &--table {
    font-size: 16px;

    background: rgb(239, 239, 239);
    color: $color-head;
    z-index: 0;

    &::after {
      background: #ffffff;
      transform-origin: 50% 50%;
      transition: $default-transition-settings;
      transform: scaleY(0);
    }

    &:hover,
    &:focus {
      color: #000;
      transition: $default-transition-settings;
    }

    &:hover::after,
    &:focus::after {
      opacity: 1;
      transform: scaleY(1);
    }
  }
}