.form-reset-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading {
  display: block;
  margin: 0;
  margin-bottom: 40px;
  padding: 0;
  font-size: 21px;
  font-weight: 700;
  color: $color-head;
  text-align: center;
}

.form-errors {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      color: $color-error;
    }
  }

  text-align: center;
}

.form-group {
  width: 50%;
  margin-bottom: 1rem;
}

.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: $color-white;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.required-field {
  color: $color-error;
}

input[type=password] {
  width: 100%;
  margin: 8px 0;
  box-sizing: border-box;
}
