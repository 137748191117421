/* ----------------- desktop ----------------- */

.completion {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  padding-top: 80px;

  background: $gradient-background;

  p {
    position: relative;

    display: block;
    width: 60%;
    margin: 0 auto;
    margin-bottom: 50px;
    padding-bottom: 50px;

    font-size: 40px;
    font-weight: 700;
    text-align: center;
    color: #ffffff;

    &::after {
      content: "";

      position: absolute;
      bottom: 0;
      left: 50%;

      display: block;
      width: 20%;
      height: 5px;

      background-color: #ffffff;
      transform: translateX(-50%);
    }
  }
}

.completion__section {
  margin-bottom: 50px;
}

.completion__footer {
  padding: 30px 0;
}

.completion__link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  max-width: 350px;
  min-width: 280px;
  margin: 0 auto;
  padding: 15px;

  font-size: 20px;
  font-weight: 500;

  border-radius: 30px;
  background-color: #ffffff;
  outline: none;

  transition: $default-transition-settings;

  &:hover,
  &:focus {
    background-color: 	#f0f0f0;
  }

  &:active {
    color: #000000;
    background-color: #e8e8e8;
  }
}

.completion__list {
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 0;

  list-style: none;
}

.completion__item {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 350px;
  padding: 0 10px;
  flex-grow: 1;

  &:last-child {
    &::after {
      display: none;
    }
  }

  &::after {
    content: "";

    position: absolute;
    right: 0;
    top: 0;

    display: block;
    width: 2px;
    height: 100%;
    
    background-color: #ffffff;
  }

  span {
    color: #ffffff;
  }
}

.completion__tag {
  font-size: 26px;
  font-weight: bold;
  text-align: center;
}

.completion__adress {
  text-align: center;
  font-size: 22px;
  color: #ffffff;
}

.completion__phone-link {
  text-align: center;
  font-size: 22px;
  line-height: 26px;
  color: #ffffff;

  &:hover,
  &:focus {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.5;
  }
}

.completion__social-list {
  display: flex;
  margin: 0;
  padding: 0;

  list-style: none;
}

.completion__social-item {
  margin: 0 10px;
}

.completion__social-link {
  outline: none;

  span {
    font-size: 0;
  }

  &:hover .completion__social-svg,
  &:focus .completion__social-svg {
    opacity: 0.7;
  }

  &:active .completion__social-svg {
    opacity: 0.4;
  }
}

.completion__social-svg {
  width: 55px;
  height: 55px;

  fill: #ffffff;

  transition: $default-transition-settings;
}

/* -----------------  mobile ----------------- */

@media (max-width: ($viewport--lg - 1)) {
  .completion {
    padding: 20px;
    padding-top: 50px;
  
    p {
      width: 100%;
  
      font-size: 24px;
  
      &::after {
        content: "";
  
        position: absolute;
        bottom: 0;
        left: 50%;
  
        display: block;
        width: 200px;
        height: 5px;
  
        background-color: #ffffff;
        transform: translateX(-50%);
      }
    }
  }

  .completion__section {
    margin-bottom: 30px;
  }

  .completion__footer {
    padding: 0;
  }

  .completion__list {
    flex-wrap: wrap;
  }

  .completion__item {
    position: relative;
  
    flex-grow: 0;
    max-width: inherit;
    width: 100%;
    padding: 15px;
    
    &:last-child {
      &::after {
        display: none;
      }
    }
  
    &::after {
      content: "";
  
      position: absolute;
      right: 50%;
      top: inherit;
      bottom: 0;
  
      width: 60%;
      min-width: 280px;
      height: 2px;
      
      transform: translateX(50%);
    }
  
    span {
      color: #ffffff;
    }
  }

  .completion__tag {
    font-size: 22px;
  }
}