// screen dimensions
//---------------------------------

$viewport--sm: 320px;
$viewport--md: 768px;
$viewport--lg: 1024px;
$viewport--xl: 1640px;

// retina
//---------------------------------

$retina-dpi: 144dpi;
$retina-dppx: 1.5dppx;

// primary colors
//---------------------------------

$color-font:#707070;
$color-head: #64186d;
$color-bright: #c336d3;
$color-green: rgba(125, 194, 68, 1);
$color-orange: #ff8c00;

$color-button-text: #66357f;
$color-soft: #c1c8ce;
$color-white: #ffffff;
$color-error: #ff3a42;
$color-button-add: rgba(124,61,152,1);
$color-background-error: rgba(227, 45, 51, 0.1);

$gradient-background: linear-gradient(148deg,#794097,#3d204c);
$gradient-button: linear-gradient(to bottom, rgba(124,61,152,1) 0%,rgba(184,102,210,1) 100%);
$gradient-aside: linear-gradient(180deg,#794097,#3d204c);

// basic fonts
//---------------------------------

$font-size-small: 12px;
$font-size-normal: 14px;
$font-size-regular: 16px;
$font-size-big: 18px;

// animations
//---------------------------------

$default-transition-settings: 0.25s ease all;
$modal-transition-settings: 0.6s cubic-bezier(0.55, 0, 0.1, 1);