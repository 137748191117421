html {
  height: 100%;

  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  height: 100%;
  width: 100%;
  min-width: $viewport--sm;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;

  font-family: 'Gisha', 'Arial', sans-serif;
  font-size: $font-size-regular;
  color: $color-font;

  background-color: #ffffff;
}

a {
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;

  border: 0;

  clip: rect(0 0 0 0);
  overflow: hidden;
}

/* CSSTransition classes  */

.popup-animation-enter {
  opacity: 0;
}

.popup-animation-enter-active {
  opacity: 1;
  transition: all 0.3s ease;
}

.popup-animation-exit-active {
  opacity: 0;
  
  transition: all 0.3s ease;
}

.popup-animation-exit-done {
  opacity: 0;
}