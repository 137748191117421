/* ----------------- desktop ----------------- */

.homepage {
  position: relative;

  min-height: 100vh;

  background: $gradient-background;

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
  
    transform: translate(-50%, -50%);
  }
}

.homepage__section {
  min-height: 100vh;
  padding-top: 60px;
  padding-bottom: 60px;

  background-color: #ffffff;
}

.homepage__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  span {
    font-size: 18px;
    font-weight: bold;
    color: $color-orange;
  }
}

.homepage__logo-image {
  display: block;
  width: 330px;
  height: auto;
}

.homepage__button-svg {
  display: none;
}

.homepage__active-quizzes,
.homepage__finished-quizzes {
  margin-bottom: 40px;

  h2 {
    margin: 0;
    margin-bottom: 40px;
    padding: 0;

    font-size: 40px;
    font-weight: 700;
    color: $color-head;
    text-align: center;
  }
}

.homepage__finished-quizzes {
  margin-bottom: 0;
}

.homepage__quizzes-span {
  display: flex;
  justify-content: center;

  font-size: 24px;
  font-weight: 700;
}

.homepage__table-button,
.homepage__test-link {
  max-width: 80%;
  height: 35px;
  margin: 0 auto;

  font-family: 'Gisha', 'sans-serif';
  font-size: 18px;
  text-align: center;

  &:disabled {
    background-color: rgba(0, 0, 0, 0.3);

    pointer-events: none;

    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}

.homepage__table {
  width: 100%;
  display: table;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;

  border: none;
  // box-shadow: 0px 35px 50px rgba( 0, 0, 0, 0.2 );
}

.homepage__tr {
  border-bottom: 2px solid rgba(0, 0, 0, 0.3);

  &:nth-child(2n) {
    background: #e9e9e9;
  }

  &:nth-child(2n - 1) {
    background: #ffffff;
  }
}

.homepage__th {
  padding: 15px;

  font-size: 18px;
  text-align: center;

  color: #ffffff;
  background: #324960;

  &:nth-child(2n) {
    background-color: #07A0C3;
  }
}

.homepage__td {
  padding: 20px 0;

  font-family: 'Gisha', 'sans-serif';
  font-size: 18px;
  text-align: center;
  color: #000;
}

.homepage__cell-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  max-width: 280px;
  margin: 0 auto;
  
  span {
    display: block;
    width: 100%;

    overflow: hidden;
    text-overflow: ellipsis; 
  }
}

/* -----------------  320px - 768px (mobile) ----------------- */

@media (max-width: ($viewport--md)) {
  .homepage {
    padding: 0;
  }

  .homepage__section {
    padding: 30px 0;
    padding-bottom: 0;
  }

  .homepage__header {
    padding: 0 5%;
  }

  .homepage__logo-image {
    width: 220px;
  }

  .homepage__button {
    width: calc(130px + (180 - 120) * ((100vw - 568px) / (768 - 568)));
  }

  .homepage__table-button {
    span {
      font-size: 16px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; 
    }
  }
}

/* -----------------  320px - 568px (mobile) ----------------- */

@media (max-width: ($viewport--md - 200)) {
  .homepage__logo-image {
    width: 170px;
  }

  .homepage__button {
    width: 40px;
    height: 40px;

    font-size: 0;
    background-color: inherit;
    box-shadow: none;

    border: 2px solid $color-head;
    border-radius: 100%;

    &::after {
      display: none;
    }

    &:hover,
    &:focus {
      .homepage__button-svg {
        fill: $color-bright;
      }
    }

    &:active {
      box-shadow: none;

      .homepage__button-svg {
        opacity: 0.7;
      }
    }
  }

  .homepage__button-svg {
    display: block;
    width: 80%;
    height: auto;
    fill: $color-head;

    transition: $default-transition-settings;
  }

  .homepage__active-quizzes,
  .homepage__finished-quizzes {
    h2 {
      margin-bottom: 30px;

      font-size: 24px;
    }
  }

  .homepage__table {
    display: block;
    margin: 0 auto;

    thead {
      display: none;
    }

    tbody {
      display: block;
    }
  }

  .homepage__tr {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 35px;

    border: none;
  }

  .homepage__td {
    position: relative;

    display: flex;
    justify-content: flex-end;
    width: 100%;
    min-height: 40px;
    padding: 15px;
    
    font-size: 18px;
    text-align: start;

    border-bottom: 1px solid rgba(0, 0, 0, 0.3);

    &::after {
      content: attr(data-name);

      position: absolute;
      left: 10%;
      top: 50%;

      font-size: 18px;

      transform: translateY(-50%);
    }

    span {
      display: flex;
      width: 50%;
      max-width: 170px;
    }

    &:last-child {
      border-bottom: none;
    }
  }
  
  .homepage__cell-container {
    width: 50%;
    max-width: 170px;
    margin: 0;

    span {
      justify-content: left;
      width: 100%;
      height: 25px;
    }
  }

  .homepage__test-link {
    width: 50%;
    margin: 0;
  }

  .homepage__table-button {
    margin: 0;

    span {
      justify-content: center;
    }
  }

  .button {
    &--table {
      width: 100%;
      height: 25px;
  
      font-size: 14px;
    }
  }
}

