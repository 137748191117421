.loading {
  margin: 0 auto;
  
  overflow: hidden;
  background: #ffffff;

  span {
    display: block;
    height: fit-content;

    text-align: center;
    font-size: 32px;
    
    color: $color-head;
  }

  .loading__additional-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    & > span {
      display: block;
      width: 50%;
      margin: 0 auto;
      margin-bottom: 2rem;
    }
  }

  .loading__button-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.loading-circle {
  width: 200px;
  height: 200px;
  margin: 45px auto;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

.loading-circle div > div {
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: $color-head;
  animation: loading-circle 1s linear infinite;
}

.loading-circle div:nth-child(1) > div {
  left: 148px;
  top: 88px;
  animation-delay: -0.875s;
}

.loading-circle > div:nth-child(1) {
  transform: rotate(0deg);
  transform-origin: 160px 100px;
}

.loading-circle div:nth-child(2) > div {
  left: 130px;
  top: 130px;
  animation-delay: -0.75s;
}

.loading-circle > div:nth-child(2) {
  transform: rotate(45deg);
  transform-origin: 142px 142px;
}

.loading-circle div:nth-child(3) > div {
  left: 88px;
  top: 148px;
  animation-delay: -0.625s;
}

.loading-circle > div:nth-child(3) {
  transform: rotate(90deg);
  transform-origin: 100px 160px;
}

.loading-circle div:nth-child(4) > div {
  left: 46px;
  top: 130px;
  animation-delay: -0.5s;
}

.loading-circle > div:nth-child(4) {
  transform: rotate(135deg);
  transform-origin: 58px 142px;
}

.loading-circle div:nth-child(5) > div {
  left: 28px;
  top: 88px;
  animation-delay: -0.375s;
}

.loading-circle > div:nth-child(5) {
  transform: rotate(180deg);
  transform-origin: 40px 100px;
}

.loading-circle div:nth-child(6) > div {
  left: 46px;
  top: 46px;
  animation-delay: -0.25s;
}

.loading-circle > div:nth-child(6) {
  transform: rotate(225deg);
  transform-origin: 58px 58px;
}

.loading-circle div:nth-child(7) > div {
  left: 88px;
  top: 28px;
  animation-delay: -0.125s;
}

.loading-circle > div:nth-child(7) {
  transform: rotate(270deg);
  transform-origin: 100px 40px;
}

.loading-circle div:nth-child(8) > div {
  left: 130px;
  top: 46px;
  animation-delay: 0s;
}

.loading-circle > div:nth-child(8) {
  transform: rotate(315deg);
  transform-origin: 142px 58px;
}

.loading-circle div { 
  box-sizing: content-box; 
}

@keyframes loading-circle {
  0% {
    opacity: 1;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1.5,1.5);
  } 
  
  100% {
    opacity: 0;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1,1);
  }
}