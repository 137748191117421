.forgot-password-modal {
  position:fixed;
  top:20%;
  left:0;
  right:0;
  bottom:0;
  z-index: 1;

  text-align:center;

  &-content{
    width: 100%;
    padding: 20px 40px;
    border-radius: 10px;
    display: inline-block;
    vertical-align: middle;
    max-width: 600px;
    margin: 10px auto;
    background: linear-gradient(148deg, #794097, #3d204c);

    &-heading {
      color: $color-white;
    }

    &-errors {
      color: $color-error;
      margin: 0 0 20px 0;
    }

    &-success {
      color: $color-green;
      margin: 0 0 20px 0;
    }

    button {
      padding: 10px 20px;
      border-radius: 5px;
      color: $color-font;
      background: $color-white;
      margin: 0 10px;
    }
  }

  /* Full-width input fields */
  input[type=text] {
    width: 100%;
    padding: 12px 20px;
    display: inline-block;
    border: 1px solid $color-font;
    box-sizing: border-box;
    border-radius: 5px;
    margin: 20px 0;
  }
}