/* ----------------- desktop ----------------- */

.linear-progress {
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;

  border-radius: 50px;
  border: 3px solid $color-soft;
  background: #e0e0de;
}

.linear-progress__fill {
  display: flex;
  align-items: center;
  justify-content: center;

  .linear-progress__label {
    padding: 8px;
  
    font-weight: bold;
    color: #ffffff;
  }
}

