/* ----------------- desktop ----------------- */

.autorization {
  display: block;
  min-height: 100vh;
  padding: 75px 0;

  background: $gradient-background;
}

.autorization__section {
  max-width: 1500px;
  min-height: 80vh;
  margin: 0 auto;
  padding: 40px calc(40px + (80 - 40) * ((100vw - 1024px) / (1920 - 500)));

  background-color: #ffffff;
}

.autorization__header {
  margin-bottom: 40px;
}

.autorization__logo-image {
  width: 330px;
  height: auto;
}

.autorization__login-container {
  display: flex;
}

.autorization__preview-image {
  width: 50%;
  max-width: 645px;
  height: auto;
  margin-right: auto;
}

.autorization__form {
  max-width: 603px;
  margin-left: 35px;
  z-index: 0;

  h2 {
    margin: 0;
    margin-bottom: 10px;

    font-size: 38px;
    color: $color-head;
  }

  p {
    margin-bottom: 10px;

    font-size: 20px;
    line-height: 24px;
    font-weight: normal;
  }
}

.autorization__fieldset {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  margin-bottom: 20px;
  padding: 0;

  border: none;
}

.autorization__form-list {
  width: 100%;
  margin: 0;
  padding: 0;

  list-style: none;
}

.autorization__form-item {
  position: relative;

  width: 100%;
  padding-top: 20px;

  font-size: 14px;

  svg {
    position: absolute;
    top: 50%;
    left: 15px;

    width: 30px;
    height: 30px;

    transform: translateY(-50%);
  }
}

.autorization__form-input {
  width: 100%;
  padding: 12px;

  font-size: 16px;

  border: 2px solid #dddddd;
  border-radius: 3px;
  background: none;

  outline: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  transition: 0.25s ease all;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }

  &:hover {
    border-color: $color-head;
    transition: $default-transition-settings;
  }

  &:focus {
    border-color: $color-bright;
    transition: $default-transition-settings;
  }

  &:valid,
  &:focus {
    transition-delay: 0.1s;
  }
  
  &:-webkit-autofill + .autorization__form-placeholder,
  &:valid + .autorization__form-placeholder,
  &:not(:empty) + .autorization__form-placeholder,
  &:focus + .autorization__form-placeholder {
    top: 20px;

    font-size: 10px;
    color: #222222;
    font-weight: bold;

    border-color: #222222;
    background-color: #ffffff;
  }
}

.autorization__form-placeholder {
  position: absolute;
  top: calc(50% + 10px);
  left: 6px;

  padding: 0 7px;

  color: #aaaaaa;

  transform: translateY(-50%);
  transition: 0.25s ease all;
  pointer-events: none;
}

.autorization__checkbox-container {
  display: flex;
  align-items: center;
  margin-right: auto;
}

.autorization__checkbox-label {
  display: flex;
  align-items: center;

  cursor: pointer;

  span {
    transition: 0.3s ease-in-out all;
  }
  
  &:hover {
    span {
      color: $color-head;
    }

    .svg-checkbox__box {
      stroke: $color-head;
    }
  }

  &:active {
    span {
      opacity: 0.9;
    }
  }
}

.autorization__checkbox {
  pointer-events: none;

  &:focus + label {
    span {
      color: $color-head;
    }

    .svg-checkbox__box {
      stroke: $color-head;
    }
  }

  &:checked + label {
    .svg-checkbox__box {
      fill:#66FF66;
      stroke-dashoffset: 320;
    }
  
    .svg-checkbox__check {
      stroke-dashoffset: 0;
    }
  }
}

.autorization__checkbox-svg {
  width: 30px;
  height: 30px;
  margin-right: 10px;

  .svg-checkbox__box {
    fill: #ffffff;
    stroke: #dddddd;
    stroke-width: 6;
    stroke-miterlimit: 10;
    stroke-dasharray: 320;
    stroke-dashoffset: 0;

    transition-delay: 0s, 0s, 0.4s;
    transition-property: stroke, stroke-dashoffset, fill;
    transition-duration: 0.3s, 0.45s, 0.4s;
  }

  .svg-checkbox__check {
    fill: none;
    stroke: $color-head;
    stroke-width: 6;
    stroke-miterlimit: 10;
    stroke-dasharray: 80;
    stroke-dashoffset: 80;

    transition: stroke-dashoffset 0.3s linear;
  }
}

.autorization__forget-link {
  position: relative;
  color: $color-font;

  outline: none;
  transition: $default-transition-settings;

  &::after {
    content: "";

    position: absolute;
    left: 0;
    bottom: 0;

    display: block;
    width: 0;
    height: 1px;

    background-color: $color-bright;
    transition: $default-transition-settings;
  }

  &:hover,
  &:focus {
    color: $color-bright;

    &::after {
      width: 100%;
    }
  }

  &:active {
    opacity: 0.8;
  }
}

.autorization__button {
  margin: 0 auto;
}

.autorization__social-container {
  width: fit-content;
  margin: 0 auto;
  padding-top: 40px;

  h3 {
    margin: 0;
    margin-bottom: 15px;

    font-size: 22px;
    font-weight: normal;
  }
}

.autorization__social-list {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;

  list-style: none;
}

.autorization__social-item {
  margin: 0 10px;
}

.autorization__social-link {
  outline: none;

  span {
    font-size: 0;
  }

  &:hover .autorization__social-svg,
  &:focus .autorization__social-svg {
    fill: $color-bright;
  }

  &:active .autorization__social-svg {
    fill: $color-head;
  }
}

.autorization__social-svg {
  width: 55px;
  height: 55px;

  fill: #3b5998;

  transition: $default-transition-settings;
}

.autorization__alert-container {
  height: 20px;
  margin-bottom: 10px;
}

.autorization__alert {
  display: block;
  width: 100%;
  margin: 0;
  padding: 5px 15px;

  color: $color-error;

  background-color: $color-background-error;
  
  transition: $default-transition-settings;

  &::first-letter {
    text-transform: uppercase;
  }

  &--loading {
    color: #ffffff;
    background: $color-green;
  }
}

.autorization__alert-animation {
  opacity: 0;
}

.autorization__alert-animation-enter {
  opacity: 0;
}

.autorization__alert-animation-enter-active {
  opacity: 1;
  transition: all 0.5s ease;
}

.autorization__alert-animation-exit-active {
  opacity: 0;
  transition: all 0.5s ease;
}

.autorization__alert-animation-exit-done {
  opacity: 0;
}

/* ----------------- 768px - 1024px tablet ----------------- */

@media (max-width: ($viewport--lg - 1)) {
  .autorization__section {
    padding: 35px;
  }

  .autorization__header {
    margin-bottom: 30px;
  }
  
  .autorization__logo-image {
    width: 250px;
  }

  .autorization__login-container {
    flex-wrap: wrap;
  }

  .autorization__preview-image {
    width: 80%;
    max-width: inherit;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .autorization__form {
    width: 80%;
    max-width: inherit;
    margin: 0 auto;
  
    h2 {
      font-size: 32px;
      text-align: center;
    }
  
    p {
      max-width: 600px;
      margin: 0 auto;

      font-size: 16px;
      line-height: 20px;
      text-align: center;
    }
  }
}

/* -----------------  320px - 768px (mobile) ----------------- */

@media (max-width: ($viewport--md - 1)) {
  .autorization {
    padding: 0;
  }

  .autorization__section {
    padding: 15px;
  }

  .autorization__logo-image {
    width: calc(200px + (250 - 200) * ((100vw - 320px) / (1920 - 320)));
    margin: 0 auto;
  }

  .autorization__preview-image {
    width: 90%;
  }

  .autorization__form { 
    width: 100%;
    h2 {
      font-size: 24px;
    }
  
    p {
      font-size: 14px;
      margin: 5px 0;
    }
  }

  .autorization__social-container {
    h3 {
      text-align: center;
    }
  }
}
