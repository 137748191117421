.begin-button{

/* UI Properties */
text-align: center;
font: normal normal 300 40px/51px Futura PT;
letter-spacing: 8px;
color: #FFFFFF;
opacity: 1;
left: 500px;
width: 700px;
height: 80px;

/* UI Properties */
background: transparent linear-gradient(90deg, #794097 0%, #942C9E 42%, #FB00FB 100%) 0% 0% no-repeat padding-box;
opacity: 1;
margin-top: 20px;
}

.e-signature{
padding:10px;
width:35%;
}
